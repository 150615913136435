import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Card, CardBody } from "reactstrap";
import logo from "../../assets/img/logo/logo.png";
import { connect } from "react-redux";
import {
  checkVerificationCode,
  forgotPass,
  saveForgotPass,
} from "../../client/actions/auth";
import FormInput from "../../components/form/FormInput";

const ResetPassword = (props) => {
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [username, setUsername] = useState("");
  const [verificationCode, setVerificationCode] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");

  const [showCodeScreen, setShowCodeScreen] = useState(false);
  const [showResetScreen, setShowResetScreen] = useState(false);
  const [showUserScreen, setShowUserScreen] = useState(true);

  useEffect(() => {
    makeFocus();
  });

  useEffect(() => {
    if (props?.auth !== null) {
      if (props?.auth?.status) {
        if (props.auth.status === true) {
          if (props.auth.nextPage === "enterCode") {
            setSuccessMessage(props.auth.message);
            setShowCodeScreen(true);
            setShowUserScreen(false);
            setShowResetScreen(false);
          }
          if (props.auth.nextPage === "newPass") {
            setSuccessMessage(props.auth.message);
            setShowCodeScreen(false);
            setShowUserScreen(false);
            setShowResetScreen(true);
          }
          if (props.auth.nextPage === "complete") {
            setSuccessMessage(props.auth.message);
            navigate("/");
            setShowCodeScreen(false);
            setShowUserScreen(false);
            setShowResetScreen(true);
          }
        } else {
          setErrorMessage(props.auth.message);
        }
      }
    }
  }, [props, navigate]);

  const makeFocus = () => {
    try {
      window.username.focus();
    } catch {}
  };

  const onSubmit = () => {
    props.forgotPass(username);
  };

  const onSubmitVerification = () => {
    props.checkVerificationCode(verificationCode, username);
  };

  const onSubmitPassword = () => {
    props.saveForgotPass(username, verificationCode, password, passwordConfirm);
  };

  return (
    <>
      <Card>
        <CardBody>
          <div className="col-12 text-center">
            <img
              src={logo}
              alt="Simply Collaborate, Inc"
              className="img-fluid"
              width="250"
              height="250"
            />
          </div>
          {errorMessage && (
            <div className="col-12">
              <div className="alert alert-success" role="alert">
                {errorMessage}
              </div>
            </div>
          )}
          {successMessage && (
            <div className="col-12">
              <div className="alert alert-success text-black p-2" role="alert">
                {successMessage}
              </div>
            </div>
          )}
          <div className="m-sm-4">
            {showUserScreen === true && (
              <>
                <div className="text-center">
                  <h1 className="h2">Reset password</h1>
                  <p className="lead">
                    Enter your email to reset your password.
                  </p>
                </div>
                <form onSubmit={(e) => e.preventDefault()}>
                  <FormInput
                    colSize={1}
                    hideBorder={true}
                    type="email"
                    id="username"
                    label="Username / Email"
                    name="username"
                    value={username}
                    placeholder="Enter your username"
                    onChange={(fieldType, e) => setUsername(e.target.value)}
                  />
                  <div className="row">
                    <div className="col-6 mt-3">
                      <Link to="/auth/sign-in" className="btn btn-secondary">
                        Back
                      </Link>
                    </div>
                    <div className="col-6 text-end mt-3">
                      <button
                        className="btn btn-primary"
                        onClick={() => onSubmit()}
                      >
                        Reset password
                      </button>
                    </div>
                  </div>
                </form>
              </>
            )}
            {showCodeScreen === true && (
              <>
                <div className="text-center">
                  <h1 className="h2">Reset password</h1>
                  <p className="lead">
                    Check your email and enter the code from your email below.
                  </p>
                </div>
                <form onSubmit={(e) => e.preventDefault()}>
                  <FormInput
                    colSize={1}
                    hideBorder={true}
                    label={"Verification Code"}
                    type="text"
                    name="verificationCode"
                    onChange={(fieldType, e) =>
                      setVerificationCode(e.target.value)
                    }
                    autoComplete="section-red verificationCode"
                    value={verificationCode}
                  />
                  <div className="row">
                    <div className="col-6 mt-3">
                      <Link to="/auth/sign-in" className="btn btn-secondary">
                        Back
                      </Link>
                    </div>
                    <div className="col-6 text-end mt-3">
                      <button
                        className="btn btn-primary"
                        onClick={() => onSubmitVerification()}
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </form>
              </>
            )}
            {showResetScreen === true && (
              <>
                <div className="text-center">
                  <h1 className="h2">Reset password</h1>
                  <p className="lead">Enter your new password</p>
                </div>
                <form onSubmit={(e) => e.preventDefault()}>
                  <FormInput
                    colSize={1}
                    hideBorder={true}
                    label={"Password"}
                    type="password"
                    name="password"
                    className="form-control"
                    autoComplete="section-red new-password"
                    value={password}
                    onChange={(fieldType, e) => setPassword(e.target.value)}
                  />
                  <FormInput
                    colSize={1}
                    hideBorder={true}
                    label={"Confirm Password"}
                    type="password"
                    name="passwordConfirm"
                    onChange={(fieldType, e) =>
                      setPasswordConfirm(e.target.value)
                    }
                    className="form-control"
                    autoComplete="section-red new-password"
                    value={passwordConfirm}
                  />
                  <div className="row">
                    <div className="col-6 mt-3">
                      <Link to="/auth/sign-in" className="btn btn-secondary">
                        Back
                      </Link>
                    </div>
                    <div className="col-6 text-end mt-3">
                      <button
                        className="btn btn-primary"
                        onClick={() => onSubmitPassword()}
                      >
                        <i className="fas fa-unlock-alt" /> Submit
                      </button>
                    </div>
                  </div>
                </form>
              </>
            )}
          </div>
        </CardBody>
      </Card>
    </>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth.user,
});

export default connect(mapStateToProps, {
  forgotPass,
  checkVerificationCode,
  saveForgotPass,
})(ResetPassword);
