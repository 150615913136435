import React from "react";
import { Link } from "react-router-dom";

const Account = ({ account, subscriptionTypes }) => {
  let subscription;
  if (account.subscription === false) {
    subscription = { name: "No Subscription" };
  } else {
    subscription = subscriptionTypes.filter(
      (sub) => sub.id === account.subscription.subscriptionTypeId,
    )[0];
  }

  return (
    <tr>
      <td>{account.workspace}</td>
      <td>{account.companyName}</td>
      <td>{account.contactName}</td>
      <td>{account.contactPhone}</td>
      <td>{subscription.name}</td>
      <td>{account.status === "1" ? "Pending" : "Approved"}</td>
      <td>
        <Link to={`/accounts/${account.id}`} className="btn btn-warning btn-sm">
          Manage
        </Link>
      </td>
    </tr>
  );
};
export default Account;
