import React, { useEffect } from "react";
import { Card, CardBody, CardHeader, CardTitle } from "reactstrap";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { DataListTable } from "./DataListTable";
import { SearchFunction } from "./SearchFunction";
import { DataListAddButtons } from "./DataListAddButtons";

const DataList = (props) => {
  const {
    tableData,
    settings,
    searchText,
    tableName,
    columns,
    title,
    sortFieldName,
    searchKeyPress,
    resetSearch,
    sortDirection,
    slogan,
    onAdd,
    changeValue,
    searchOverview,
    recordsPerPage,
    addTitle,
    onChangeRecordsPerPage,
    onDelete,
    onInvite,
    onEdit,
    onExport,
    onUpdatePage,
    onViewsUsers,
    changeSort,
    currentPage,
    totalPages,
    totalRecords,
    organizationId,
    userPermissions,
    route,
    parentRoute,
    onResendInvite,
  } = props;

  let hasStatus = false;

  const onArchive = (id) => {
    props.archiveSubDataRecord(tableName, id);
  };

  useEffect(() => {}, [props]);

  if (settings?.showCard === false) {
    return (
      <>
        <h6 className="card-subtitle text-muted">{slogan}</h6>
        <br />
        <div className="row align-items-center">
          <DataListAddButtons
              {...props}
          />
          <SearchFunction
            settings={settings}
            searchText={searchText}
            searchOverview={searchOverview}
            resetSearch={resetSearch}
            changeValue={changeValue}
            searchKeyPress={searchKeyPress}
          />
        </div>
        <div className="row">
          <div className="col-12">
            <DataListTable
              columns={columns}
              route={route}
              parentRoute={parentRoute}
              currentPage={currentPage}
              totalPages={totalPages}
              onUpdatePage={onUpdatePage}
              recordsPerPage={recordsPerPage}
              sortDirection={sortDirection}
              sortFieldName={sortFieldName}
              hasStatus={hasStatus}
              tableData={tableData}
              tableName={tableName}
              onEdit={onEdit}
              onDelete={onDelete}
              onArchive={onArchive}
              changeSort={changeSort}
              onChangeRecordsPerPage={onChangeRecordsPerPage}
              totalRecords={totalRecords}
              onViewsUsers={onViewsUsers}
              onResendInvite={onResendInvite}
              userPermissions={userPermissions}
            />
          </div>
        </div>
      </>
    );
  } else {
    return (
      <Card>
        <CardHeader>
          {tableName === "users" &&
            organizationId !== "" &&
            typeof organizationId !== "undefined" && (
              <div className="row mb-2">
                <div className="col-6">
                  <CardTitle tag="h5">{title}</CardTitle>
                  <h6 className="card-subtitle text-muted">{slogan}</h6>
                </div>
                <div className="col-6 text-end">
                  <Link
                    className="btn btn-dark btn-sm"
                    to="/settings/customers"
                  >
                    Back to Organizations
                  </Link>
                </div>
              </div>
            )}
          {tableName !== "users" && (
            <div className="row mb-2">
              <div className="col-12">
                <CardTitle tag="h5">{title}</CardTitle>
                <h6 className="card-subtitle text-muted">{slogan}</h6>
              </div>
            </div>
          )}
          <div className="row align-items-center">
            <DataListAddButtons
                {...props}
            />
            <SearchFunction
                {...props}
            />
          </div>
        </CardHeader>
        <CardBody>
          <DataListTable
            route={route}
            parentRoute={parentRoute}
            columns={columns}
            currentPage={currentPage}
            totalPages={totalPages}
            onUpdatePage={onUpdatePage}
            recordsPerPage={recordsPerPage}
            sortDirection={sortDirection}
            sortFieldName={sortFieldName}
            hasStatus={hasStatus}
            tableData={tableData}
            tableName={tableName}
            onEdit={onEdit}
            onDelete={onDelete}
            onArchive={onArchive}
            changeSort={changeSort}
            onChangeRecordsPerPage={onChangeRecordsPerPage}
            totalRecords={totalRecords}
            onViewsUsers={onViewsUsers}
            onResendInvite={onResendInvite}
            userPermissions={userPermissions}
          />
        </CardBody>
      </Card>
    );
  }
};

const mapStateToProps = (state) => ({
  auth: state.user,
  userPermissions: state.auth.userRole,
  data: state.pages.data,
});

export default connect(mapStateToProps, {})(DataList);
