import React, { useEffect, useState } from "react";
import DataOverview from "../components/data/DataOverview";
import { useLocation, useMatch, useParams } from "react-router-dom";
import Loader from "../components/layouts/Loader";

const tableName = "amountOfDiskSpace";
const editPathName = "/amount-of-disk-space/:id";
const tableColumns = [
  {
    displayName: "Name",
    dataField: "name",
    sort: true,
  },
  {
    displayName: "Cost",
    dataField: "cost",
    sort: true,
  },
  {
    displayName: "Sort",
    dataField: "sortOrder",
    sort: true,
  },
  {
    displayName: "Tools",
    dataField: "tools",
    sort: false,
  },
];
const fieldData = [
  {
    displayName: "Name",
    placeholder: "Name",
    fieldName: "name",
    fieldType: "text",
    colSize: 3,
    required: true,
    requiredMessage: "Field is required",
  },
  {
    displayName: "Cost",
    placeholder: "Cost",
    fieldName: "cost",
    fieldType: "number",
    colSize: 3,
    required: true,
    requiredMessage: "Field is required",
  },
  {
    displayName: "Sort Order",
    placeholder: "Sort Order",
    fieldName: "sortOrder",
    fieldType: "number",
    colSize: 3,
    required: true,
    requiredMessage: "Field is required",
  },
];
const fieldDataDefault = {
  name: "",
  cost: 0,
};

const AmountOfDiskSpace = () => {
  const { id } = useParams();
  const location = useLocation();
  const match = useMatch(editPathName);
  const [loading, setLoading] = useState(true);
  let parentRoute = location.pathname;
  useEffect(() => {
    setLoading(false);
  }, []);

  if (match) {
    parentRoute = match.pattern.path.replace("/:id", "");
  }

  if (loading) return <Loader />;
  return (
    <DataOverview
      id={id ?? ""}
      tableName={tableName}
      pageMode={id ? "edit" : "list"}
      route={location.pathname}
      parentRoute={parentRoute}
      sortFieldName={"sortOrder"}
      sortDirection={"ASC"}
      pageTitle={"Amount of Disk Space"}
      listTitle={"Amount of Disk Space"}
      listSubTitle={""}
      addTitle={"Add Disk Space"}
      addSubTitle={""}
      editTitle={"Edit Disk Space"}
      editSubTitle={""}
      tableColumns={tableColumns}
      fieldData={fieldData}
      fieldDataDefault={fieldDataDefault}
    />
  );
};

export default AmountOfDiskSpace;
