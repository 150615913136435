import React, { useEffect, useState } from "react";
import DataOverview from "../components/data/DataOverview";
import { useLocation, useMatch, useParams } from "react-router-dom";
import Loader from "../components/layouts/Loader";

const tableName = "content";
const editPathName = "/settings/content/:id";
const tableColumns = [
  {
    dataField: "pageUrl",
    sort: true,
  },
  {
    dataField: "pageTitle",
    sort: true,
  },
  {
    dataField: "tools",
    sort: false,
  },
];
const fieldData = [
  {
    displayName: "Page URL",
    placeholder: "Page URL",
    fieldName: "pageUrl",
    fieldType: "text",
    colSize: 2,
    required: true,
    requiredMessage: "Page URL",
  },
  {
    fieldName: "pageTitle",
    fieldType: "text",
    colSize: 2,
    required: true,
    requiredMessage: "Page Title",
  },
  {
    fieldName: "pageHTML",
    fieldType: "content",
    colSize: 1,
    required: false,
    requiredMessage: "Page Title",
  },
];
const fieldDataDefault = {
  pageUrl: "",
  pageTitle: "",
  pageHTML: "",
};

const Content = () => {
  const { id } = useParams();
  const location = useLocation();
  const match = useMatch(editPathName);
  const [loading, setLoading] = useState(true);
  let parentRoute = location.pathname;
  useEffect(() => {
    setLoading(false);
  }, []);

  if (match) {
    parentRoute = match.pattern.path.replace("/:id", "");
  }

  if (loading) return <Loader />;
  return (
    <DataOverview
      id={id ?? ""}
      tableName={tableName}
      pageMode={id ? "edit" : "list"}
      route={location.pathname}
      parentRoute={parentRoute}
      pageTitle={"Content"}
      listTitle={"Content"}
      listSubTitle={""}
      addTitle={"Add Content"}
      addSubTitle={""}
      editTitle={"Edit Content"}
      editSubTitle={""}
      tableColumns={tableColumns}
      fieldData={fieldData}
      fieldDataDefault={fieldDataDefault}
    />
  );
};

export default Content;
