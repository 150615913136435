import React, { Fragment, useEffect } from "react";
import classnames from "classnames";
import ShowInputError from "./ShowInputError";

const FormInputSelect = (props) => {
  let {
    name,
    value,
    colSize,
    defaultValue,
    field,
    label,
    type,
    placeholder,
    helpText,
    dropdown,
    options,
    dataError,
    onChange,
    error,
    inputSizeClass,
  } = props;

  if (typeof dropdown === "undefined" && typeof options !== "undefined") {
    dropdown = options;
  }
  let divColSize;
  if (colSize === 0) divColSize = 0;
  if (colSize === 1) divColSize = 12;
  if (colSize === 2) divColSize = 6;
  if (colSize === 3) divColSize = 4;
  if (colSize === 4) divColSize = 8;
  if (
    (value === "" || typeof value === "undefined") &&
    defaultValue !== "" &&
    typeof defaultValue !== "undefined"
  ) {
    value = defaultValue;
  }

  useEffect(() => {}, [props]);

  return (
    <div className={`col-12 align-bottom col-sm-${divColSize}`}>
      <div className="container p-0">
        <div className="form-group row border-bottom pb-2">
          <label
            htmlFor={name}
            className={classnames("col-sm-12 m-0 pt-2 pb-2", {
              "is-invalid": dataError,
            })}
          >
            {label}
          </label>
          <div className="col-sm-12">
            <select
              className={classnames(inputSizeClass, {
                "is-invalid": error,
              })}
              id={name}
              name={name}
              value={value}
              onChange={(e) => onChange(type, e, field)}
            >
              <option value="">-- Select {placeholder} --</option>
              {dropdown?.length > 0 &&
                dropdown.map((options, index) => {
                  return (
                    <Fragment key={index}>
                      {options.name !== "" &&
                        typeof options.name != "undefined" && (
                          <option value={options.id}>{options.name}</option>
                        )}
                      {options.Name !== "" &&
                        typeof options.Name != "undefined" && (
                          <option value={options.ID}>{options.Name}</option>
                        )}
                      {options.Name !== "" &&
                        typeof options.Name != "undefined" && (
                          <option value={options.ID}>{options.Name}</option>
                        )}
                      {(options.Name === "" ||
                        typeof options.Name === "undefined") &&
                        options.name === "" &&
                        typeof options.name === "undefined" && (
                          <option value={index}>{options}</option>
                        )}
                    </Fragment>
                  );
                })}
            </select>
          </div>
          {helpText && (
            <small id={name + "Help"} className="col-12 form-text text-muted">
              {helpText}
            </small>
          )}
          {error && <ShowInputError error={error} />}
        </div>
      </div>
    </div>
  );
};

export default FormInputSelect;
