import React, { useEffect, useState } from "react";
import DataOverview from "../components/data/DataOverview";
import { useLocation, useMatch, useParams } from "react-router-dom";
import Loader from "../components/layouts/Loader";
import { connect } from "react-redux";
import { getList } from "../client/actions/apiActions";

const tableName = "subscriptionTypes";
const editPathName = "/subscription-types/:id";
const tableColumns = [
  {
    dataField: "name",
    text: "Name",
    displayName: "Name",
    sort: true,
  },
  {
    dataField: "price",
    displayName: "Price",
    sort: true,
  },
  {
    dataField: "sortOrder",
    displayName: "Sort",
    sort: true,
  },
  {
    dataField: "status",
    displayName: "Status",
    type: "bool",
    sort: true,
  },
  {
    dataField: "tools",
    displayName: "Tools",
    text: "Tools",
    sort: false,
  },
];
const fieldData = [
  {
    fieldName: "name",
    displayName: "Name",
    placeholder: "Name",
    fieldType: "text",
    colSize: 2,
    required: true,
    requiredMessage: "Field is required!",
  },
  {
    fieldName: "price",
    displayName: "Price",
    placeholder: "",
    fieldType: "text",
    colSize: 2,
    required: false,
  },
  {
    fieldName: "sortOrder",
    displayName: "Sort",
    placeholder: "",
    fieldType: "number",
    colSize: 2,
    required: false,
  },
  {
    fieldName: "status",
    displayName: "Status",
    placeholder: "Status",
    fieldType: "toggle",
    colSize: 2,
    required: false,
    requiredMessage: "Field is required!",
  },
  {
    fieldName: "devSquareSubscriptionId",
    displayName: "DevSquare Subscription Id",
    placeholder: "DevSquare Subscription Id",
    fieldType: "text",
    colSize: 2,
    required: true,
    requiredMessage: "Field is required!",
  },
  {
    fieldName: "squareSubscriptionId",
    displayName: "Prod Square Subscription Id",
    placeholder: "Prod Square Subscription Id",
    fieldType: "text",
    colSize: 2,
    required: true,
    requiredMessage: "Field is required!",
  },
  {
    fieldName: "features",
    displayName: "Features",
    placeholder: "Features",
    fieldType: "textarea",
    colSize: 1,
    required: false,
  },
  {
    fieldName: "amountOfDiskSpace",
    displayName: "Amount Of Disk Space",
    placeholder: "Amount Of Disk Space",
    fieldType: "dropdown",
    colSize: 2,
    required: false,
  },
  {
    fieldName: "numberOfClients",
    displayName: "Number of Clients",
    placeholder: "Number of Clients",
    fieldType: "dropdown",
    colSize: 2,
    required: false,
  },
];
const fieldDataDefault = {
  name: "",
  sort: "",
  price: "",
  status: true,
};

const SubscriptionTypes = (props) => {
  const { id } = useParams();
  const { pathname } = useLocation();
  const match = useMatch(editPathName);
  const [loading, setLoading] = useState(true);

  const [reload, setReload] = useState(true);
  let parentRoute = pathname;

  useEffect(() => {
    props.getList("numberOfClients", ["amountOfDiskSpace"]);
  }, []);

  useEffect(() => {
    setLoading(false);
    if (props.data.numberOfClients) {
      fieldData[8].options = props.data.numberOfClients;
    }
    if (props.data.amountOfDiskSpace) {
      fieldData[7].options = props.data.amountOfDiskSpace;
    }
  }, [props.data]);

  useEffect(() => {
    // reload
    setReload(!reload);
  }, [id]);

  if (match) {
    parentRoute = match.pattern.path.replace("/:id", "");
  }

  if (loading) return <Loader />;
  let title = "Subscription Types";
  return (
    <DataOverview
      id={id ?? ""}
      tableName={tableName}
      pageMode={id ? "edit" : "list"}
      route={pathname}
      parentRoute={parentRoute}
      sortFieldName={"sortOrder"}
      sortDirection={"ASC"}
      pageTitle={title}
      listTitle={title}
      listSubTitle={""}
      addTitle={`Add ${title}`}
      addSubTitle={""}
      editTitle={`Edit ${title}`}
      editSubTitle={""}
      tableColumns={tableColumns}
      fieldData={fieldData}
      fieldDataDefault={fieldDataDefault}
    />
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  data: state.pages.data,
});

export default connect(mapStateToProps, { getList })(SubscriptionTypes);
