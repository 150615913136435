import React from "react";
import DataOverview from "../components/data/DataOverview";
import { useLocation, useMatch, useParams } from "react-router-dom";
import { connect } from "react-redux";
import { getList } from "../client/actions/apiActions";

const title = "Referral Codes";
const tableName = "referralCodes";
const editPathName = "/referral-program/:id";
const tableColumns = [
  {
    dataField: "name",
    text: "Name",
    displayName: "Name",
    sort: true,
  },
  {
    dataField: "code",
    text: "Code",
    displayName: "Code",
    sort: true,
  },
  {
    dataField: "status",
    displayName: "Status",
    type: "bool",
    sort: true,
  },
  {
    dataField: "tools",
    displayName: "Tools",
    text: "Tools",
    sort: false,
  },
];
const fieldData = [
  {
    fieldName: "name",
    displayName: "Name",
    placeholder: "Name",
    fieldType: "text",
    colSize: 3,
    required: true,
    requiredMessage: "Field is required!",
  },
  {
    fieldName: "code",
    displayName: "Code",
    placeholder: "Code",
    fieldType: "text",
    colSize: 3,
    required: true,
    requiredMessage: "Field is required!",
  },
  {
    fieldName: "status",
    displayName: "Status",
    placeholder: "Status",
    fieldType: "toggle",
    colSize: 3,
    required: false,
    requiredMessage: "Field is required!",
  },
];
const fieldDataDefault = {
  name: "",
  sort: "",
  price: "",
  status: true,
};

const SubscriptionTypes = (props) => {
  const { id } = useParams();
  const { pathname } = useLocation();
  const match = useMatch(editPathName);
  let parentRoute = pathname;

  if (match) {
    parentRoute = match.pattern.path.replace("/:id", "");
  }

  return (
    <DataOverview
      id={id ?? ""}
      tableName={tableName}
      pageMode={id ? "edit" : "list"}
      route={pathname}
      parentRoute={parentRoute}
      sortFieldName={"status"}
      sortDirection={"ASC"}
      pageTitle={title}
      listTitle={title}
      listSubTitle={""}
      addTitle={`Add ${title}`}
      addSubTitle={""}
      editTitle={`Edit ${title}`}
      editSubTitle={""}
      tableColumns={tableColumns}
      fieldData={fieldData}
      fieldDataDefault={fieldDataDefault}
    />
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  data: state.pages.data,
});

export default connect(mapStateToProps, { getList })(SubscriptionTypes);
