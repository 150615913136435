function getEnvironment() {
  let ApiURL;
  if (window.location.hostname.indexOf("dev") !== -1) {
    ApiURL = "https://cp.simplycollaborate.com/api";
  } else if (window.location.hostname.indexOf("localhost") !== -1) {
    ApiURL = "https://cp.simplycollaborate.com/api";
  } else {
    ApiURL = "https://cp.simplycollaborate.com/api";
  }
  return ApiURL;
}

export const locationId = "8AHACSTCAFPVV";
export const appId = "sandbox-sq0idb-xlxF1YKo4ZVvpbNW8X7_lQ";
export const ApiURL = getEnvironment();
export const defaultLang = "en";

export const BaseImageURL = "https://cp.simplycollaborate.com";
export const WebsiteURL = window.location.host;

export const timezones = [
  {
    id: 1,
    name: "GMT - Greenwich Mean Time - +0",
  },
  {
    id: 2,
    name: "UTC - Universal Coordinated Time - 0",
  },
  {
    id: 3,
    name: "ECT - European Central Time - GMT+1:00",
  },
  {
    id: 4,
    name: "EET - Eastern European Time - GMT+2:00",
  },
  {
    id: 5,
    name: "ART - (Arabic) Egypt Standard Time - GMT+2:00",
  },
  {
    id: 6,
    name: "MET - Middle East Time - GMT+3:30",
  },
  {
    id: 7,
    name: "NET - Near East Time - GMT+4:00",
  },
  {
    id: 8,
    name: "PLT - Pakistan Lahore Time - GMT+5:00",
  },
  {
    id: 9,
    name: "IST - India Standard Time - GMT+5:30",
  },
  {
    id: 10,
    name: "BST - Bangladesh Standard Time - GMT+6:00",
  },
  {
    id: 11,
    name: "VST - Vietnam Standard Time - GMT+7:00",
  },
  {
    id: 12,
    name: "CTT - China Taiwan Time - GMT+8:00",
  },
  {
    id: 13,
    name: "JST - Japan Standard Time - GMT+9:00",
  },
  {
    id: 14,
    name: "ACT - Australia Central Time - GMT+9:30",
  },
  {
    id: 15,
    name: "AET - Australia Eastern Time - GMT+10:00",
  },
  {
    id: 16,
    name: "SST - Solomon Standard Time - GMT+11:00",
  },
  {
    id: 17,
    name: "NST - New Zealand Standard Time - GMT+12:00",
  },
  {
    id: 18,
    name: "MIT - Midway Islands Time - GMT-11:00",
  },
  {
    id: 19,
    name: "HST - Hawaii Standard Time - GMT-10:00",
  },
  {
    id: 20,
    name: "AST - Alaska Standard Time - GMT-9:00",
  },
  {
    id: 21,
    name: "PST - Pacific Standard Time - GMT-8:00",
  },
  {
    id: 22,
    name: "PNT - Phoenix Standard Time - GMT-7:00",
  },
  {
    id: 23,
    name: "MST - Mountain Standard Time - GMT-7:00",
  },
  {
    id: 24,
    name: "CST - Central Standard Time - GMT-6:00",
  },
  {
    id: 25,
    name: "EST - Eastern Standard Time - GMT-5:00",
  },
  {
    id: 26,
    name: "IET - Indiana Eastern Standard Time - GMT-5:00",
  },
  {
    id: 27,
    name: "PRT - Puerto Rico and US Virgin Islands Time - GMT-4:00",
  },
  {
    id: 28,
    name: "CNT - Canada Newfoundland Time - GMT-3:30",
  },
  {
    id: 29,
    name: "AGT - Argentina Standard Time - GMT-3:00",
  },
  {
    id: 30,
    name: "BET - Brazil Eastern Time - GMT-3:00",
  },
  {
    id: 31,
    name: "CAT - Central African Time - GMT-1:00",
  },
];
