import React, { useEffect, useState } from "react";
import { timezones } from "../config";
import DataOverview from "../components/data/DataOverview";
import Loader from "../components/layouts/Loader";
import { useLocation, useMatch, useParams } from "react-router-dom";

const tableName = "users";
const editPathName = "/organizations/users/:organizationId/:id";
const tableColumns = [
  {
    dataField: "active",
    type: "bool",
    sort: false,
  },
  {
    dataField: "image",
    type: "image",
    sort: true,
  },
  {
    dataField: "firstName",
    sort: true,
  },
  {
    dataField: "lastName",
    sort: true,
  },
  {
    dataField: "email",
    sort: true,
  },
  {
    dataField: "role",
    sort: true,
  },
  {
    dataField: "tools",
    sort: false,
  },
];
const fieldData = [
  {
    fieldName: "titleUsersName",
    fieldType: "title",
    colSize: 1,
  },
  {
    fieldName: "firstName",
    fieldType: "text",
    colSize: 3,
    required: true,
  },
  {
    fieldName: "lastName",
    fieldType: "text",
    colSize: 3,
    required: true,
  },
  {
    fieldName: "active",
    fieldType: "toggle",
    colSize: 3,
  },
  {
    fieldName: "titleLogInfo",
    fieldType: "title",
    colSize: 1,
  },
  {
    fieldName: "email",
    fieldType: "email",
    colSize: 1,
  },
  {
    fieldName: "titleContactInfo",
    fieldType: "title",
    colSize: 1,
  },
  {
    fieldName: "cellPhone",
    fieldType: "phone",
    colSize: 2,
  },
  {
    fieldName: "timezone",
    fieldType: "dropdown",
    dropdownData: timezones,
    colSize: 2,
  },
  {
    fieldName: "image",
    fieldType: "image",
    colSize: 1,
  },
  {
    fieldName: "notes",
    fieldType: "textarea",
    colSize: 1,
  },

  {
    fieldName: "titleRoleInfo",
    fieldType: "title",
    colSize: 1,
  },
  {
    fieldName: "userRole",
    fieldType: "radio",
    colSize: 1,
    required: true,
  },

  {
    fieldName: "titleAddressInfo",
    fieldType: "title",
    colSize: 1,
  },
  {
    fieldName: "address",
    fieldType: "geoLocate",
    colSize: 1,
  },
  {
    fieldName: "addressLatitude",
    fieldType: "hidden",
    colSize: 0,
  },
  {
    fieldName: "addressLongitude",
    fieldType: "hidden",
    colSize: 0,
  },
  {
    fieldName: "organizationId",
    fieldType: "hidden",
    colSize: 0,
  },
];
const fieldDataDefault = {};

const Users = () => {
  const { id } = useParams();
  let { organizationId } = useParams();
  const { pathname } = useLocation();
  const match = useMatch(editPathName);
  const [loading, setLoading] = useState(true);
  let parentRoute = pathname;

  useEffect(() => {
    setLoading(false);
  }, []);

  if (match) {
    parentRoute = match.pattern.path.replace("/:id", "");
    parentRoute = parentRoute.replace("/:organizationId", "/" + organizationId);
  }
  if (loading) return <Loader />;

  return (
    <DataOverview
      id={id ?? ""}
      organizationId={organizationId ?? ""}
      tableName={tableName}
      pageMode={id ? "edit" : "list"}
      route={pathname}
      parentRoute={parentRoute}
      pageTitle={'Users'}
      listTitle={'Users'}
      listSubTitle={''}
      addTitle={'Add User'}
      addSubTitle={''}
      editTitle={'Edit User'}
      editSubTitle={''}
      tableColumns={tableColumns}
      fieldData={fieldData}
      fieldDataDefault={fieldDataDefault}
    />
  );
};
export default Users;
