import React, { useEffect, useState } from "react";
import { Container } from "reactstrap";
import { useNavigate } from "react-router-dom";
import DataList from "./DataList";
import DataEdit from "./DataEdit";
import {
  archiveRecord,
  deleteRecord,
  exportOverview,
  getRecord,
  getTable,
  inviteUser,
  saveRecord,
  testEmail,
} from "../../client/actions/apiActions";
import { connect } from "react-redux";
import Loader from "../layouts/Loader";
import { confirmAlert } from "react-confirm-alert";
import { ApiURL } from "../../config";
import "react-confirm-alert/src/react-confirm-alert.css";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const DataOverview = (props) => {
  const navigate = useNavigate();
  const [pageMode, setPageMode] = useState("loading");
  const [savePageMode, setSavePageMode] = useState(props.savePageMode ?? "");
  const [pageTitle] = useState("");
  const [singleEdit] = useState(props.singleEdit ?? false);
  const [addTitle] = useState(props.addTitle ?? "");
  const [listTitle] = useState(props.listTitle ?? "");
  const [listSubTitle] = useState(props.listSubTitle ?? "");
  const [tableName] = useState(props.tableName);
  const [tableColumns] = useState(props.tableColumns ?? []);
  const [tableData, setTableData] = useState([]);
  const [fieldData] = useState(props.fieldData ?? []);
  const [fieldDataResults, setFieldDataResults] = useState(
    props.fieldDataResults ?? {}
  );
  const [fieldDataErrors, setFieldDataErrors] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [searchText, setSearchText] = useState(props.searchText ?? "");
  const [searchColumns, setSearchColumns] = useState(props.searchColumns ?? []);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(
    props.recordsPerPage ?? 25
  );
  const [sortDirection, setSortDirection] = useState(
    props.sortDirection ?? "ASC"
  );
  const [sortFieldName, setSortFieldName] = useState(props.sortFieldName ?? "");
  const [settings, setSettings] = useState(props.settings ?? {});
  const [organizationId, setOrganizationId] = useState(
    props.organizationId ?? ""
  );
  const [showFilters, setShowFilters] = useState(false);
  const [filterText, setFilterText] = useState("");
  const [filters, setFilters] = useState([]);
  const [id, setId] = useState(props.id !== "" ? props.id : 0);

  // MODAL
  const [showModal, setShowModal] = useState(false);

  console.log("load");

  useEffect(() => {
    console.log('useEffect', props.id, id);
    if (props.id !== id) {
      setId(props.id);
    }
    if (props.id) {
      editFunction(props.id);
    } else if (singleEdit === false) {
      props.getTable(
          props.tableName,
          1,
          recordsPerPage,
          sortFieldName,
          sortDirection,
          searchText,
          organizationId,
          searchColumns,
          []
      );
    }
  }, [props.id]);

  useEffect(() => {
    if (
      props.pageMode === "list" &&
      pageMode !== "list" &&
      pageMode !== "loading"
    ) {
      setPageMode("list");
      // refreshFunction();
    } else {
      if (
        props.data.dataType === "export" &&
        props.data.status === 1 &&
        props.data.code !== "" &&
        typeof props.data.code !== "undefined"
      ) {
        window.open(ApiURL + "/export/" + props.data.code);
      } else if (props.data.dataType === "addRecord") {
        setFieldDataResults(props.data.dataValues);
        setPageMode("add");
      } else if (props.data.dataType === "getRecord") {
        if (fieldDataResults !== props.data.dataValues) {
          setFieldDataResults(props.data.dataValues);
          setPageMode("edit");
        }
      } else if (props.data.dataType === "deleteRecord") {
        refreshFunction();
      } else if (props.data.dataType === "saveRecord") {
        if (props.data.status === 1) {
          toast.success("Record was successfully saved.", {
            theme: "colored",
          });
        } else {
          toast.danger("Error Saving", {
            theme: "colored",
          });
        }
        if (!singleEdit && savePageMode === "close") {
          //refreshFunction();
          navigate(props.parentRoute);
        } else {
          props.getRecord(tableName, id);
        }
      } else if (props.data.dataType === "archiveRecord") {
        refreshFunction();
      } else if (props.data.dataType === "getTable") {
        setTableData(props.data.dataValues ?? []);
        setCurrentPage(props.data.currentPage);
        setTotalPages(props.data.totalPages);
        setTotalRecords(props.data.totalRecords);
        setPageMode("list");
      }
    }
  }, [props]);

  useEffect(() => {
    console.log("id", id, id ?? "");
    if (id !== 0) {
      props.getRecord(tableName, id);
    } else {
      refreshFunction();
    }
  }, [id]);

  useEffect(() => {}, [tableData, pageMode]);

  const refreshFunction = (
    clickedCurrentPage = 0,
    clickedRecordsPerPage = "",
    clickedSortFieldName = "",
    clickedSortDirection = "",
    clickedSearchText = "",
    clickedSearchColumns = "",
    clickedFilters = ""
  ) => {
    setPageMode("loading");
    props.getTable(
      tableName,
      clickedCurrentPage ? clickedCurrentPage : currentPage,
      clickedRecordsPerPage ? clickedRecordsPerPage : recordsPerPage,
      clickedSortFieldName ? clickedSortFieldName : sortFieldName,
      clickedSortDirection ? clickedSortDirection : sortDirection,
      clickedSearchText ? clickedSearchText : searchText,
      organizationId,
      clickedSearchColumns ? clickedSearchColumns : searchColumns,
      clickedFilters ? clickedFilters : filters
    );
  };
  const addFunction = () => {
    setPageMode("loading");
    props.getRecord(tableName, "0");
  };
  const editFunction = (clickedId) => {
    setPageMode("loading");
    props.getRecord(tableName, clickedId);
  };
  const deleteFunction = (id) => {
    props.deleteRecord(tableName, id);
  };
  const archiveRecord = (id) => {
    props.archiveRecord(tableName, id);
  };
  const updatePage = (clickedCurrentPage) => {
    setCurrentPage(clickedCurrentPage);
    refreshFunction(clickedCurrentPage, recordsPerPage);
  };
  const deleteConfirmFunction = (id) => {
    confirmAlert({
      title: "Confirm Deletion",
      message: "Are you sure you wish to delete?",
      buttons: [
        {
          label: "Yes",
          onClick: () => deleteFunction(id),
        },
        {
          label: "No",
        },
      ],
    });
  };
  const testEmail = () => {
    props.testEmail();
  };

  const saveFunction = (saveId, saveMethod, data) => {
    let prevPageMode = pageMode;
    setPageMode("loading");

    let errors = 0;

    let fieldDataErrors = [];
    if (organizationId && tableName === "users") {
      data["organizationId"] = organizationId;
    }
    fieldData.map((field) => {
      if (
        field.required === true &&
        (data[field.fieldName] === "" ||
          typeof data[field.fieldName] === "undefined")
      ) {
        errors = 1;
        let errorMessage = field.requiredMessage;
        fieldDataErrors[field.fieldName] = errorMessage;
        toast.error(errorMessage, {
          theme: "colored",
        });
      }
      return null;
    });

    if (errors === 1) {
      setPageMode(prevPageMode);
      setFieldDataErrors(fieldDataErrors);
    } else {
      if (saveMethod === "save") {
        setSavePageMode("edit");
      }

      if (saveMethod === "save and close") {
        setSavePageMode("close");
      }

      if (saveMethod === "save and duplicate") {
        setSavePageMode("duplicate");
      }

      if (saveMethod === "clone") {
        setSavePageMode("edit");
      }
      props.saveRecord(tableName, saveId, data);
      setFieldDataErrors([]);
    }
  };
  const exportFunction = () => {
    props.exportOverview(tableName);
  };
  const resetSearch = () => {
    setSearchText("");
    setPageMode("loading");
    refreshFunction(
      currentPage,
      recordsPerPage,
      sortFieldName,
      sortDirection,
      searchText
    );
  };
  const searchOverview = () => {
    setPageMode("loading");
    refreshFunction();
  };
  const searchKeyPress = (e) => {
    if (e.key === "Enter") {
      searchOverview();
    }
  };
  const closeFunction = () => {
    setPageMode("loading");
    setFieldDataErrors([]);
    setId(0);
    refreshFunction();
  };

  const onViewsUsers = (organizationId) => {
    navigate("/organizations/users/" + organizationId);
  };
  const changeSort = (clickedSortFieldName, clickedSortDirection) => {
    if (clickedSortDirection === "DESC") {
      clickedSortDirection = "ASC";
    } else {
      clickedSortDirection = "DESC";
    }

    setPageMode("loading");
    setSortFieldName(clickedSortFieldName);
    setSortDirection(clickedSortDirection);

    refreshFunction(
      currentPage,
      recordsPerPage,
      clickedSortFieldName,
      clickedSortDirection
    );
  };

  const toggleFilters = () => {
    setShowFilters(!showFilters);
  };
  const updateFilter = (newFilter) => {
    setFilters(newFilter);
    refreshFunction(
      currentPage,
      recordsPerPage,
      sortFieldName,
      sortDirection,
      searchText,
      searchColumns,
      newFilter
    );
  };

  const changeRecordsPerPage = (e) => {
    setCurrentPage(1);
    // this.setState({ [e.target.name]: e.target.value, currentPage: 1 });
    refreshFunction(1, e.target.value);
  };

  useEffect(() => {}, [showModal]);

  return (
    <Container fluid className="p-0">
      <h1 className="h3 mb-3">{pageTitle}</h1>
      {pageMode === "loading" && <Loader />}
      {pageMode === "list" && (
        <DataList
          route={props.route}
          parentRoute={props.parentRoute}
          title={listTitle}
          addTitle={addTitle}
          slogan={listSubTitle}
          columns={tableColumns}
          tableData={tableData}
          tableName={tableName}
          sortFieldName={sortFieldName}
          sortDirection={sortDirection}
          searchText={searchText}
          settings={settings}
          currentPage={currentPage}
          totalPages={totalPages}
          totalRecords={totalRecords}
          recordsPerPage={recordsPerPage}
          organizationId={organizationId}
          showFilters={showFilters}
          filters={filters}
          filterText={filterText}
          updateFilter={updateFilter}
          resetSearch={resetSearch}
          onViewsUsers={onViewsUsers}
          searchOverview={searchOverview}
          searchKeyPress={searchKeyPress}
          onUpdatePage={updatePage}
          onAdd={addFunction}
          onExport={exportFunction}
          toggleFilters={toggleFilters}
          onChangeRecordsPerPage={changeRecordsPerPage}
          changeSort={changeSort}
          onEdit={editFunction}
          onDelete={deleteConfirmFunction}
          onArchive={archiveRecord}
        />
      )}
      {(pageMode === "add" || pageMode === "view" || pageMode === "edit") && (
        <DataEdit
          route={props.route}
          parentRoute={props.parentRoute}
          mode={pageMode}
          singleEdit={singleEdit}
          title={props[pageMode + "Title"]}
          slogan={props[pageMode + "SubTitle"]}
          tableName={tableName}
          organizationId={organizationId}
          settings={settings}
          dataFields={fieldData}
          dataResults={fieldDataResults}
          dataErrors={fieldDataErrors}
          onArchive={archiveRecord}
          onSave={saveFunction}
          onCancel={closeFunction}
          testEmail={testEmail}
        />
      )}
      <ToastContainer />
    </Container>
  );
};
const mapStateToProps = (state) => ({
  auth: state.auth.user,
  data: state.pages.data,
});

export default connect(mapStateToProps, {
  getTable,
  getRecord,
  saveRecord,
  archiveRecord,
  testEmail,
  inviteUser,
  deleteRecord,
  exportOverview,
})(DataOverview);
