import {Button} from "reactstrap";
import {Search as SearchIcon, XOctagon as ResetIcon} from "react-feather";
import React from "react";

export const SearchFunction = ({ settings, searchText, searchOverview, resetSearch, changeValue, searchKeyPress }) => {
	if (typeof(settings) !== 'undefined') {
		if (settings.showSearch !== false) {
			return (<div className="col-12 col-sm">
				<div className="row align-items-center">
					<div className="col-12">
						<div className="input-group">
							<span className="input-group-text" id="basic-addon3">Search Term:</span>
							<input
								id="searchText"
								name="searchText"
								value={searchText}
								className="form-control"
								onKeyPress={(e) => { searchKeyPress(e); } }
								onChange={(e) => changeValue('searchText', e)}
							/>
							<Button color="success" onClick={() => searchOverview() }>
								<SearchIcon size={18} /> {' '}
								Search
							</Button>
							<Button color="secondary" onClick={() => resetSearch() }>
								<ResetIcon size={18} /> {' '}
								Reset
							</Button>
						</div>
					</div>
				</div>
			</div>);
		}
	}

	return (null);
}
