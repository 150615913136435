import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

class Footer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <footer className="footer">
        <div className="container">
          <div className="row text-muted">
            <div className="col-6 text-start">&nbsp;</div>
            <div className="col-6 text-end">
              <p className="mb-0">
                &copy; {new Date().getFullYear()} -{" "}
                <span className="text-muted">Simply Collaborate, LLC</span>
              </p>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}

export default connect((store) => ({
  app: store.app,
  auth: store.auth,
}))(Footer);
