import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  cancelSubscription,
  deleteSubscriptionAction,
  getAccount,
  restartSubscription,
  setupDatabase,
  setupDomain,
  updateCreditCard,
} from "../../client/actions/apiActions";
import { Link, useParams } from "react-router-dom";
import Loader from "../../components/layouts/Loader";
import AccountManageCreditCard from "./AccountManage/AccountManageCard";
import PaymentHistory from "./AccountManage/PaymentHistory";
import AccountSetup from "./AccountManage/AccountSetup";
import WebhookHistory from "./AccountManage/WebhookHistory";
import UserLog from "./AccountManage/UserLog";
import BillingInfo from "./AccountManage/BillingInfo";
import {
  ChevronLeft as BackIcon,
  RefreshCw as ReloadIcon,
} from "react-feather";

const AccountManage = (props) => {
  const { data } = props;
  const { id } = useParams();
  const [account, setAccount] = useState();
  const [accountSettings, setAccountSettings] = useState([]);
  const [userLog, setUserLog] = useState([]);
  const [webhooks, setWebhooks] = useState([]);
  const [nameOnCard, setNameOnCard] = useState("");
  const [squareCustomer, setSquareCustomer] = useState();
  const [squareSubscription, setSquareSubscription] = useState();
  const [squareInvoices, setSquareInvoices] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (data.account && data.account !== account) {
      if (data.account.contactName) {
        setNameOnCard(data.account.contactName);
      }
      setAccount(data.account);
      setLoading(false);
    }

    if (data.accountSettings && data.accountSettings !== accountSettings) {
      setAccountSettings(data.accountSettings);
    }

    if (data.accountLog && data.accountLog !== userLog) {
      setUserLog(data.accountLog);
    }

    if (data.squareCustomer && data.squareCustomer !== squareCustomer) {
      setSquareCustomer(data.squareCustomer.customer);
    }

    if (data.webhooks && data.webhooks !== webhooks) {
      setWebhooks(data.webhooks);
    }

    if (
      data.squareSubscription &&
      data.squareSubscription !== squareSubscription
    ) {
      setSquareSubscription(data.squareSubscription.subscription);
    }

    if (data.squareInvoices && data.squareInvoices !== squareInvoices) {
      setSquareInvoices(data.squareInvoices.invoices);
    }
  }, [data]);

  useEffect(() => {
    props.getAccount(id);
  }, [id]);

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-6">
            <h3>Manage Subscription</h3>
            <Link to="/accounts" className="btn btn-sm btn-secondary mb-4">
              <BackIcon />
            </Link>{" "}
            &nbsp;
            <button
              className="btn btn-sm btn-warning mb-4"
              onClick={() => props.getAccount(id)}
            >
              <ReloadIcon />
            </button>
          </div>
          <div className="col-6 text-end">
            {squareSubscription?.status && (
              <div className="bg-success p-2 float-end text-white rounded">
                <b>{squareSubscription?.status}</b>
              </div>
            )}
          </div>
        </div>
        <div className="row">
          <div className="col-6">
            <BillingInfo
              id={id}
              account={account}
              squareSubscription={squareSubscription}
              getAccount={props.getAccount}
            />
            <PaymentHistory
              id={id}
              getAccount={props.getAccount}
              squareInvoices={squareInvoices}
              squareSubscription={squareSubscription}
              setSquareSubscription={setSquareSubscription}
              setSquareInvoices={setSquareInvoices}
            />
            <WebhookHistory
              id={id}
              getAccount={props.getAccount}
              webhooks={webhooks}
            />
          </div>
          <div className="col-6">
            <AccountManageCreditCard
              id={id}
              getAccount={props.getAccount}
              squareCustomer={squareCustomer}
              card={{
                name: nameOnCard,
                number: `••••••••••••${squareCustomer?.cards[0]?.last_4}`,
                type: squareCustomer?.cards[0]?.card_brand.toLowerCase(),
                expiration: `${squareCustomer?.cards[0]?.exp_month}/${squareCustomer?.cards[0]?.exp_year}`,
                cvc: "•••",
              }}
            />
            {account.workspace !== "dev" && (
              <AccountSetup
                id={id}
                getAccount={props.getAccount}
                account={account}
                accountSettings={accountSettings}
              />
            )}
            <UserLog userLog={userLog} />
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  data: state.pages.data,
});

export default connect(mapStateToProps, {
  getAccount,
  updateCreditCard,
  setupDomain,
  setupDatabase,
  cancelSubscription,
  restartSubscription,
  deleteSubscriptionAction,
})(AccountManage);
