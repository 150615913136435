import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  cancelSubscription,
  deleteSubscriptionAction,
  restartSubscription,
} from "../../../client/actions/apiActions";
import { Spinner } from "reactstrap";

const BillingInfo = (props) => {
  const { squareSubscription, account, id } = props;

  console.log("squareSubscription", squareSubscription);

  const [buttonLoading, setButtonLoading] = useState(false);

  const cancelSubscription = () => {
    setButtonLoading(true);
    props.cancelSubscription(squareSubscription.id);
  };

  const restartSubscription = () => {
    setButtonLoading(true);
    props.restartSubscription(squareSubscription.id);
  };

  const deleteSubscriptionAction = () => {
    setButtonLoading(true);
    let cancelId = squareSubscription.actions.filter(
      (item) => item.type === "CANCEL",
    )[0]["id"];
    props.deleteSubscriptionAction(squareSubscription.id, cancelId);
  };

  useEffect(() => {
    if (
      props.billingInfo.dataType === "deleteSubscriptionAction" ||
      props.billingInfo.dataType === "cancelSubscription"
    ) {
      setButtonLoading(false);
      props.getAccount(id);
    }
  }, [props.billingInfo]);

  let status = squareSubscription?.status;

  return (
    <div className="card">
      <div className="card-header">
        <h4>Billing Info</h4>
      </div>
      <div className="card-body">
        <table className="table table-sm">
          <tbody>
            <tr>
              <td>Name:</td>
              <td>
                <h5>
                  {account.companyName}
                  <br />
                  {account.contactName}
                </h5>
              </td>
            </tr>
            <tr>
              <td>Address:</td>
              <td>
                {account.personalAddress1}
                {account.personalAddress2 && (
                  <>
                    <br />
                    {account.personalAddress2}
                  </>
                )}
                <br />
                {account.personalCity}, {account.personalState}{" "}
                {account.personalZip}
              </td>
            </tr>
            {account.sameAsPersonal === "1" ? (
              <>
                <tr>
                  <td>Phone:</td>
                  <td>
                    <a href={`tel: ${account.personalPhone}`}>
                      {account.personalPhone}
                    </a>
                  </td>
                </tr>
                <tr>
                  <td>Email:</td>
                  <td>
                    <a href={`mailto: ${account.username}`}>
                      {account.username}
                    </a>
                  </td>
                </tr>
              </>
            ) : (
              <>
                <tr>
                  <td>Phone:</td>
                  <td>{account.billingPhone}</td>
                </tr>
                <tr>
                  <td>Email:</td>
                  <td>{account.username}</td>
                </tr>
                <tr>
                  <td>Address:</td>
                  <td>
                    {account.billingAddress1}
                    {account.billingAddress2 && (
                      <>
                        <br />
                        {account.billingAddress2}
                      </>
                    )}
                    <br />
                    {account.billingCity}, {account.billingState}{" "}
                    {account.billingZip}
                  </td>
                </tr>
                <tr>
                  <td>&nbsp;</td>
                  <td></td>
                </tr>
              </>
            )}
            {squareSubscription?.status && (
              <tr>
                <td>Status:</td>
                <td>
                  <h3
                    className={` ${
                      status === " ACTIVE" ? "text-success" : "text-danger"
                    }`}
                  >
                    {status === "ACTIVE" &&
                    squareSubscription?.canceled_date <=
                      squareSubscription?.charged_through_date
                      ? "Pending Cancel"
                      : squareSubscription?.status}
                  </h3>
                </td>
              </tr>
            )}
            {squareSubscription?.start_date && (
              <tr>
                <td>Start Date:</td>
                <td>
                  <h3>{squareSubscription.start_date}</h3>
                </td>
              </tr>
            )}
            {squareSubscription?.canceled_date && (
              <>
                <tr>
                  <td>Cancelled Date:</td>
                  <td>
                    <h3>{squareSubscription.canceled_date}</h3>
                  </td>
                </tr>
                {status === "ACTIVE" && (
                  <tr>
                    <td>Charge Through:</td>
                    <td>
                      <h3>{squareSubscription.charged_through_date}</h3>
                    </td>
                  </tr>
                )}
              </>
            )}
          </tbody>
        </table>
        {buttonLoading ? (
          <>
            <Spinner />
          </>
        ) : (
          <>
            {!squareSubscription?.canceled_date &&
              squareSubscription?.status === "ACTIVE" && (
                <button
                  className="btn btn-sm btn-danger m-1"
                  onClick={cancelSubscription}
                >
                  Cancel Subscription
                </button>
              )}
            {squareSubscription?.canceled_date &&
              squareSubscription?.status === "ACTIVE" && (
                <button
                  className="btn btn-sm btn-success m-1"
                  onClick={deleteSubscriptionAction}
                >
                  Restart Subscription (cancel action)
                </button>
              )}
            {squareSubscription?.canceled_date &&
              squareSubscription?.status !== "ACTIVE" && (
                <button
                  className="btn btn-sm btn-success m-1"
                  onClick={restartSubscription}
                >
                  Restart Subscription
                </button>
              )}
            {squareSubscription?.status === "DEACTIVATED" && (
              <button
                className="btn btn-sm btn-success m-1"
                onClick={restartSubscription}
              >
                Restart Subscription
              </button>
            )}
          </>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  billingInfo: state.pages.data,
});

export default connect(mapStateToProps, {
  restartSubscription,
  deleteSubscriptionAction,
  cancelSubscription,
})(BillingInfo);
