import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  markApproved,
  setupDatabase,
  setupDomain,
} from "../../../client/actions/apiActions";
import { Spinner } from "reactstrap";

const AccountSetup = (props) => {
  const { account, accountSettings, getAccount, id } = props;
  const [domainSetupLoading, setDomainSetupLoading] = useState(false);
  const [databaseSetupLoading, setDatabaseSetupLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const onSetupDatabase = () => {
    setDatabaseSetupLoading(true);
    props.setupDatabase(accountSettings.accountId);
  };

  const onSetupDomain = () => {
    setDomainSetupLoading(true);
    props.setupDomain(
      accountSettings.accountId,
      account.workspace + ".simplycollaborate.com",
      account.companyName,
    );
  };

  const onMarkApproved = () => {
    props.markApproved(accountSettings.accountId);
  };

  useEffect(() => {
    if (props.accountData.dataType === "setupDomain") {
      getAccount(id);
      if (!props.accountData.status) {
        setErrorMessage(props.accountData.message);
      }
      setDomainSetupLoading(false);
    }
    if (props.accountData.dataType === "setupDatabase") {
      getAccount(id);
      if (!props.accountData.status) {
        setErrorMessage(props.accountData.message);
      }
      setDomainSetupLoading(false);
    }
    if (props.accountData.dataType === "markApproved") {
      getAccount(id);
    }
  }, [props.accountData]);

  return (
    <div className="card">
      <div className="card-header">
        <h4>Setup</h4>
      </div>
      <div className="card-body">
        <table className="table table-sm table-hover">
          <tbody>
            <tr>
              <td>
                <b>Workspace:</b>
              </td>
              <td>{account.workspace}.simplycollaborate.com</td>
            </tr>
            <tr>
              <td>
                <b>Subscription Type:</b>
              </td>
              <td>
                {" "}
                {!account.subscription && <>no subscription</>}
                {account.subscription && (
                  <>
                    {account.subscription.subscriptionTypeName} /{" "}
                    {account.subscription.numberOfClientsName} /{" "}
                    {account.subscription.amountOfDiskSpaceName}
                  </>
                )}
              </td>
            </tr>
            <tr>
              <td>
                <b>Status:</b>
              </td>
              <td
                className={
                  account.status === "1" ? "text-danger" : "text-success"
                }
              >
                <b>{account.status === "1" ? "Not Setup" : "Setup"}</b>
                {account.status === "1" &&
                  accountSettings?.pleskDomainName &&
                  accountSettings?.pleskDomainName && (
                    <>
                      &nbsp;{" "}
                      <button
                        className="btn btn-sm btn-success ml-2"
                        onClick={onMarkApproved}
                      >
                        mark approved
                      </button>
                    </>
                  )}
              </td>
            </tr>
            <tr>
              <td>
                <b>Domain:</b>
              </td>
              <td>
                <b>
                  {accountSettings?.pleskDomainName ? (
                    <>{accountSettings?.pleskDomainName}</>
                  ) : (
                    <>
                      {domainSetupLoading ? (
                        <Spinner color="primary" />
                      ) : (
                        <button
                          className="btn btn-sm btn-success"
                          onClick={onSetupDomain}
                        >
                          setup domain
                        </button>
                      )}
                    </>
                  )}
                </b>
              </td>
            </tr>
            {accountSettings?.pleskDomainName && (
              <tr>
                <td>
                  <b>Database:</b>
                </td>
                <td>
                  <b>
                    {accountSettings?.pleskDatabaseName &&
                    accountSettings?.pleskDatabaseUser ? (
                      <>{accountSettings?.pleskDatabaseName}</>
                    ) : (
                      <>
                        {databaseSetupLoading ? (
                          <Spinner color="primary" />
                        ) : (
                          <button
                            className="btn btn-sm btn-success"
                            onClick={onSetupDatabase}
                          >
                            setup database
                          </button>
                        )}
                      </>
                    )}
                  </b>
                </td>
              </tr>
            )}
          </tbody>
        </table>
        <div className="container">
          <div className="row">
            <div className="col-12">
              {errorMessage && (
                <div className="alert alert-danger p-2 text-center text-dark">
                  {errorMessage}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  accountData: state.pages.data,
});

export default connect(mapStateToProps, {
  setupDomain,
  setupDatabase,
  markApproved,
})(AccountSetup);
