import { Button } from "reactstrap";
import {
  Download as DownloadIcon,
  Filter as FilterIcon,
  PlusSquare as AddIcon,
} from "react-feather";
import React from "react";
import {ApiURL} from "../../config";
import {useNavigate} from "react-router-dom";

export const DataListAddButtons = (props) => {
    const navigate = useNavigate();
  const { addTitle, toggleFilters, showFilters } =
    props;

  const exportData = () => {
      window.open(ApiURL + "/export/" + props.data.code);
  }

  const addRecord = () => {
      navigate(props.parentRoute + "/0")
  }

  console.log("DataListAddButtons props", props);

  return (
    <div className="col-12 col-sm text-start mb-2">
      <Button color="primary" onClick={addRecord} className="ms-1">
        <AddIcon size={18} /> {addTitle}
      </Button>
      <Button color="secondary" onClick={exportData} className="ms-1">
        <DownloadIcon size={18} /> Export Data
      </Button>
      <Button
        color="secondary"
        onClick={() => toggleFilters()}
        className="ms-1"
      >
        <FilterIcon size={18} /> {showFilters ? "Hide" : "Show"} Filters
      </Button>
    </div>
  );
};
