import React, { Fragment } from "react";
import classnames from "classnames";
import ShowInputError from "./ShowInputError";
import TimePicker from "react-time-picker";
import "react-time-picker/dist/TimePicker.css";
import "react-clock/dist/Clock.css";

const FormInputTime = ({
  field,
  name,
  value,
  label,
  colSize,
  placeholder,
  type,
  helpText,
  onChange,
  error,
  required,
  inputSizeClass,
}) => {
  let divColSize;
  if (colSize === 0) divColSize = 0;
  if (colSize === 1) divColSize = 12;
  if (colSize === 2) divColSize = 6;
  if (colSize === 3) divColSize = 4;
  if (colSize === 4) divColSize = 8;

  const onChangeTime = (time) => {
    onChange("custom", time, field);
  };

  return (
    <div className={`col-12 align-bottom col-sm-${divColSize}`}>
      <div className="container p-0">
        <div className="form-group row border-bottom pb-2">
          <label
            htmlFor={name}
            className={classnames("col-sm-12 m-0 pt-2 pb-2", {
              "is-invalid": error,
            })}
          >
            {label}
          </label>
          <div className="col-sm-12">
            <TimePicker
              value={value}
              onChange={onChangeTime}
              format={"hh:mm a"}
              className={classnames(inputSizeClass, { "is-invalid": error })}
            />
          </div>
          {helpText && (
            <Fragment>
              <div className="col-4">&nbsp;</div>
              <small id={name + "Help"} className="col-8 form-text text-muted">
                {helpText}
              </small>
            </Fragment>
          )}
          {error && <ShowInputError error={error} />}
        </div>
      </div>
    </div>
  );
};

export default FormInputTime;
