import React from "react";
import { Tooltip } from "@mui/material";
import { Save as SaveIcon, XCircle as CancelIcon } from "react-feather";
import { Button } from "reactstrap";
import { Link } from "react-router-dom";

const DataButtons = (props) => {
  const {
    dataResults,
    parentRoute,
    route,
    onButtonCancel,
    singleEdit,
    onSave,
    showClone,
    settings,
    tableName,
  } = props;

  return (
    <div className="container my-3">
      {!singleEdit && (
        <div className="row">
          <div className="col-6 px-0">
            <Tooltip
              arrow
              title="This will save this record but keep you on this page"
            >
              <button
                onClick={() => onSave(dataResults["id"], "save", dataResults)}
                className="ms-0 btn btn-primary"
              >
                <SaveIcon size={18} /> &nbsp; Save
              </button>
            </Tooltip>
            <Tooltip
              arrow
              title="This will save this record and take you back to the overview page"
            >
              <button
                onClick={() =>
                  onSave(dataResults["id"], "save and close", dataResults)
                }
                className="ms-1 btn btn-primary"
              >
                <SaveIcon size={18} /> &nbsp; Save & Close
              </button>
            </Tooltip>
            {showClone !== false && dataResults.id !== 0 && (
              <Tooltip
                arrow
                title="This will save a new record with this information"
              >
                <button
                  onClick={() => onSave(0, "clone", dataResults)}
                  className="ms-1 btn btn-secondary"
                >
                  <SaveIcon size={18} /> &nbsp; Save As New
                </button>
              </Tooltip>
            )}
          </div>
          <div className="col-6 px-0 text-end">
            {route === parentRoute && (
              <>
                <Button color="warning" onClick={onButtonCancel}>
                  <CancelIcon size={18} /> Cancel
                </Button>
              </>
            )}
            {route !== parentRoute && (
              <>
                <Link to={parentRoute} className="btn btn-warning">
                  {" "}
                  <CancelIcon size={18} /> Cancel
                </Link>
              </>
            )}
          </div>
        </div>
      )}
      {singleEdit && (
        <div className="row">
          <div className="col-12">
            <Button
              onClick={() => onSave(dataResults["id"], "save", dataResults)}
              color="primary"
            >
              <SaveIcon size={18} /> Save
            </Button>
            {tableName === "config" && (
              <Button
                onClick={() => props.testEmail()}
                color="primary"
                className="ms-1"
              >
                <SaveIcon size={18} /> Test Email Config
              </Button>
            )}
            {settings.showClone !== false && (
              <Button
                onClick={() => onSave(0, "clone", dataResults)}
                color="primary"
                className="ms-1"
              >
                <SaveIcon size={18} /> Clone
              </Button>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default DataButtons;
